.projects-carousel .carousel.carousel-slider .control-arrow {
    height: 50px;
    background: #fff;
    padding-right: 10px;
    opacity: 1;
    top: calc(50% - 30px)
}

.projects-carousel .carousel.carousel-slider .control-arrow.control-next {
    padding-left: 10px;
    padding-right: 7px;
}

.projects-carousel .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}

.projects-carousel .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
}

.projects-carousel .carousel .slide {
    min-width: auto !important;
    background: #fff;
}